export const enLocale = {
    "input": {
        "observation": "Observation|Observations",
        "status": "Status",
        "competency": "Competency|Competencies",
        "objectCode": "Object Code",
        "uniqueIdentifier": "Unique Identifier {name}",
        "search": "Search",
        "showInCourses": "Show in Courses",
        "showInFormations": "Show in Paths",
        "showInMidias": "Show in Media",
        "nickname": "Nickname",
        "document": "Document | Documents",
        "foreignDocument": "Foreign Document",
        "email": "E-mail",
        "password": "Password",
        "confirmPassword": "Confirm Password",
        "phone": "Phone Number",
        "gender": "Gender | Genders",
        "birthDate": "Birth Date",
        "preferredLanguage": "Preferred Language",
        "photoUpload": "Photo Upload",
        "personType": "Person Type",
        "admissionDate": "Admission Date",
        "role": "Role",
        "directSupervisor": "Immediate Superior",
        "superiorsInterests": "Superior's Interests",
        "userInterests": "User Interests",
        "smsNotifications": "SMS Notifications",
        "pushNotifications": "Push Notifications",
        "public": "Public",
        private: "Private",
        "dragAndDrop": "Drag and drop the file here <br>or",
        "category": "Category | Categories",
        "expirationDate": "Expiration Date",
        "resume": "Resume",
        "createdAt": "Creation Date",
        "certificateStructure": "Certificate Structure",
        "notShowAgain": "Do not show this item again",
        "copyTitle": "Copy - {title}",
        "paperSize": "Paper Size",
        "dpi": "DPI",
        "orientation": "Orientation"
    },
    "button": {
        "ok": "Ok",
        "close": "Close",
        "save": "Save",
        cancel: "Cancel {text}",
        remove: "Remove {text}",
        disable: "Disable {text}",
        enable: "Enable {text}",
        "viewTermsOfUse": "View Terms of Use",
        "login": "Login",
        "back": "Back",
        "send": "Send",
        "myLearning": "My Learning",
        "learnMore": "Learn More",
        "myFavorites": "My Favorites",
        continue: "Continue",
        "subscribe": "Subscribe",
        "read": "Read",
        "expired": "Expired",
        "fullscreen": "Toggle Fullscreen",
        "listen": "Listen",
        "watch": "Watch",
        "loadMore": "Load More",
        "details": "Details",
        "seeAll": "See All",
        "seeAgain": "See Again",
        "listenAgain": "Listen Again",
        "start": "Start",
        "view": "View",
        "classRoom": "Classroom",
        "access": "Access",
        "download": "Download {text}",
        "darkMode": "Dark Mode",
        "lightMode": "Light Mode",
        "export": "Export",
        "clear": "Clear {text}",
        "today": "Today",
        "yesterday": "Yesterday",
        "thisWeek": "This Week",
        "thisMonth": "This Month",
        "resetPassword": "Reset Password",
        "delete": "Delete {text}",
        "markAsRead": "Mark as read",
        "update": "Update",
        "add": "Add {text}",
        "list": "List",
        "seeMore": "See More",
        "confirm": "Confirm",
        "help": "Help",
        "clientMode": "Client Mode",
        "next": "Next",
        "saveExit": "Save and Exit",
        "expand": "Expand",
        "collapse": "Collapse",
        "hide": "Hide",
        "edit": "Edit",
        "automation": "Automation",
        "import": "Import",
        "changePermission": "Change Permission",
        "removePermission": "Remove Permission",
        "changePassword": "Change Password",
        "changeEmail": "Change E-mail",
        "validateFile": "Validate File",
        "importData": "Import Data",
        "newImport": "New Import",
        "processing": "Processing...",
        "associations": "Associations",
        "changeImage": "Change Image",
        "viewPreview": "View Preview",
        "addYoutubeLink": "Add YouTube Link",
        "insert": "Insert",
        "new": "New {text}",
        "text": "Text",
        "htmlCode": "HTML Code",
        "button": "Button",
        "checkAll": "Check All",
        "uncheckAll": "Uncheck All",
        "checkAllVisible": "Check All Visible",
        "uncheckAllVisible": "Uncheck All Visible",
        "revert": "Revert",
        "accept": "Accept",
        "tryAgain": "Try Again",
        "set": "Set",
        "exit": "Exit",
        "applyFilters": "Apply Filters",
        "resubscribe": "Resubscribe",
        "approve": "Approve",
        "deny": "Deny",
        "generateCertificate": "Generate Certificate",
        "currentItem": "Go to current question",
        previousQuestion: "Previous Question",
        nextQuestion: "Next Question",
        "submitAnswer": "Submit Answer",
        "finish": "Finish",
        "startTest": "Start Assessment",
        "continueTest": "Continue Assessment",
        "notAnswer": "Do Not Answer",
        "returnLogin": "Return to Login",
        "goClassRoom": "Go to the Classroom",
        "editUserEmail": "Edit User E-mail",
        "studentInscriptions": "Student Enrollments",
        "createQrCode": "Create QR Code for this object",
        "automateJourneys": "Automate Journeys",
        "reviewRequest": "Review Request",
        "print": "Print {text}",
        "firstPage": "First Page {text}",
        "press": "Press {text}",
        "nextPage": "Next Page {text}",
        "lastPage": "Last Page {text}",
        "lastPageViewed": "Last Page Viewed {text}",
        "unselectSelected": "Unselect selected",
        "removeSelected": "Remove selected",
        "clone": "Clone",
        "executeFlow": "Execute the notification flow manually",
        "accessLink": "Access Link",
        "exitContract": "Exit Contract",
        "listing": "Listing",
        "nextContent": "Next Content",
        "previousContent": "Previous Content",
        "leaveClassroom": "Leave Classroom",
        "addQuestions": "Add Questions",
        "backListAssessment": "Back to Knowledge Assessment List",
        "detailsAssessment": "View Assessment Details",
        "finishAssessment": "Finish Assessment",
        "continueAssessment": "Continue Assessment",
        "endAssessment": "End Assessment",
        "sendSuggestion": "Send Suggestion",
        "goToLoginSettings": "Go to Login Settings",
        "respondAssessment": "Respond to Assessment",
        "seeResult": "See Result",
        "backMyAssessments": "Back to My Assessments",
        "style": "Style",
        "parameterizations": "Parameterizations",
    },
    "labels": {
        row: "Row|Rows",
        "denied": "Denied",
        "document": "Document | Documents",
        "course": "Course | Courses",
        "trail": "Path | Paths",
        "link": "Link | Links",
        "visitLink": "Visit Link",
        "video": "Video | Videos",
        "podcast": "Podcast | Podcasts",
        "scorm": "Scorm | Scorms",
        "pdf": "PDF | PDFs",
        "image": "Image | Images",
        "photo": "Photo|Photos",
        value: "Value|Values",
        login: "Login",
        "of": "of",
        "items": "Items",
        "showing": "Showing",
        "progress": "Progress",
        "all": "All",
        "media": "Media",
        "actions": "Actions | Action",
        "notStarted": "Not Started",
        "complete": "Complete",
        "completed": "Completed",
        "listPerPage": "List per Page",
        "selected": "Selected",
        "pagination": "Showing {min} - {max} of {total}",
        "currentPage": "{current} of {total} Pages",
        "fullname": "Full Name",
        "calledBy": "Prefer to be Called",
        "preferLanguage": "My Preferred Language",
        "skill": "Competency | Competencies",
        "supervisor": "Direct Supervisor",
        "educationLevel": "Education Level",
        "unit": "Unit | Units",
        "department": "Department | Departments",
        "jobTitle": "Job Title | Job Titles",
        "pending": "Pending",
        "approved": "Approved",
        "failed": "Failed",
        "onGoing": "On Going",
        "total": "Total",
        "content": "Content | Contents",
        "user": "User | Users",
        "active": "Active|Actives",
        "inactive": "Inactive|Inactives",
        "visible": "Visible|Visibles",
        "hidden": "Hidden|Hiddens",
        "yes": "Yes",
        "no": "No",
        "filter": "Filter | Filters",
        "cnpj": "EIN",
        "cpf": "ITIN",
        "naturalPerson": "Natural Person",
        "legalEntity": "Legal Entity",
        "foreign": "Foreign",
        "companyName": "Company Name",
        "landlineNumber": "Landline Number",
        "mobilePhone": "Mobile Phone",
        "fantasyName": "Trade Name",
        "nameOrCompany": "Name or Company Name",
        "district": "District",
        "state": "State",
        "city": "City",
        "country": "Country",
        "homeNumber": "Home Number",
        "address": "Address",
        "adjunct": "Adjunct",
        "customerActivity": "Customer Activity",
        "zipCode": "Zip Code",
        "number": "Number",
        "company": "Company",
        "aliareCode": "Aliare Code",
        "date": "Date",
        "projectStatus": "Project Status",
        "products": "Products",
        "numContractedUsers": "User Limit {0}",
        "contractedDiskSpace": "Disk Usage Limit {0}",
        "communicationEmail": "Communication Email Used by the Company",
        "embedded": "Embedded",
        "partner": "Partner",
        "adhesionAgreement": "Adhesion Agreement",
        "experimentation": "Experimentation",
        "didactica": "Didactica",
        "distributor": "Distributor",
        "inProduction": "In Production",
        "blocked": "Blocked",
        "canceled": "Canceled",
        "code": "Code",
        "ordering": "Ordering",
        "asc": "Ascending",
        "desc": "Descending",
        "display": "Display",
        "settings": "Settings",
        "forceUpdatePermission": "Force Update Permission",
        "noPermission": "No Permission",
        "permission": "Permission | Permissions",
        "clickHere": "Click Here",
        "and": "and",
        "selectFile": "Select File",
        "audio": "Audio|Audios",
        "file": "File",
        "objectCode": "Code of {type}",
        "cardImage": "Card Image",
        "summary": "Summary",
        "fileName": "File Name",
        "fileSize": "File Size",
        "scormType": "Scorm Type",
        "object": "Object | Objects",
        "diagnostic": "Diagnostic",
        "reaction": "Reaction",
        "learning": "Learning",
        "applicability": "Applicability",
        "result": "Result",
        assessmentType: "Assessment Type",
        "order": "Order",
        "statement": "Statement",
        "questionType": "Question Type",
        requiredScore: "Minimum Score (%)",
        "newAttempt": "Allow New Attempt?",
        "numAttempt": "Number of Attempts",
        "automatic": "Automatic",
        "showTimer": "Add Timer to Assessment",
        assessment: "Assessment | Assessments",
        "never": "Never",
        "always": "Ever",
        "whenReject": "When Rejected",
        "objective": "Objective",
        "multipleChoice": "Multiple Choice",
        "trueOrFalse": "True / False",
        "scale": "Scale",
        "essay": "Essay",
        "typeQuestions": "Choose the Type of Question",
        "attachImage": "Attach Image",
        alternative: "Alternative|Alternatives",
        "customFeedback": "Custom Feedback?",
        "feedbackCorrect": "Feedback When Correct",
        "feedbackIncorrect": "Feedback When Incorrect",
        "feedbackResponding": "Feedback When Responding to the Question",
        "question": "Question | Questions",
        "synopsis": "Synopsis",
        "mandatorySequence": "Mandatory Sequence",
        "daysLimit": "Days Limit for Completion",
        "mandatoryCompletion": "Mandatory Completion",
        "challenge": "Challenge | Challenges",
        "termOfUse": "Term of Use|Terms of Use",
        "termOfAgreement": "Term of Agreement|Terms of Agreement",
        "section": "Section | Sections",
        "learningObjects": "Learning Objects",
        "step": "Step | Steps",
        "sortSections": "Sort Sections",
        "associate": "Associate {title}",
        "communication": "Communication | Communications",
        "insertLink": "Insert a Link",
        "use": "Use {field}",
        "segmentation": "Segmentation | Segmentations",
        "publicationPeriod": "Publication Period",
        "certificate": "Certificate | Certificates",
        "featuredCommunications": "Featured Communications",
        "post": "Post",
        "displayCommunication": "Always Display Featured Communications?",
        "event": "Event",
        "interval": "Interval",
        "daysSubmission": "Days for Submission",
        "daysNoAccess": "Days without Access to the Platform",
        "additionalRecipient": "Additional Recipient",
        "subject": "Subject",
        "draft": "Draft",
        "published": "Published",
        "copySuperior": "Copy Immediate Superior in Email?",
        "copy": "Copy",
        "noCopy": "Do Not Copy",
        "notificationEvent": "What is the Notification Event?",
        "sendingInterval": "Email Sending Interval",
        "days": "Days",
        "provideEmail": "Provide an Email for Testing.",
        "templateLayout": "Template Layout",
        "templatePreview": "Template Preview",
        "greetings": "Hello <strong>{name}</strong>, how are you?",
        "undefined": "Undefined",
        "achievements": "Achievements",
        "page": "Page | Pages",
        "classification": "Classification",
        "typeContent": "Type of Content",
        "daysCompleted": "Days to Complete",
        "rule": "Rule | Rules",
        "applicationPeriod": "Application Period",
        "to": "To",
        "completeWithin": "Complete Within",
        "score": "Score",
        "conquest": "Conquest",
        "useMedia": "Used in Media",
        "useCourses": "Used in Courses",
        "useTrails": "Used in Paths",
        "useTests": "Used in Assessments",
        "repository": "Repository | Repositories",
        "operator": "Operator",
        "or": "Or",
        "male": "Male",
        "female": "Female",
        "nonBinary": "Non-Binary",
        "debug": "Debug",
        "showGuidelines": "Show Guidelines",
        "background": "Background",
        "changeBackground": "Change Background Image",
        "removeBackground": "Remove Background Image",
        "flipHorizontally": "Flip Horizontally",
        "flipVertically": "Flip Vertically",
        "element": "Element | Elements",
        "addText": "Add Text",
        "addImage": "Add Image",
        "simulateValues": "Simulate Values",
        "preview": "Preview {text}",
        "tag": "Tag | Tags",
        "certificateId": "Certificate ID",
        option: "Option|Options",
        optional: "Optional|Optionals",
        "moreOptions": "More Options",
        "opacity": "Opacity | Opacities",
        "width": "Width",
        "height": "Height",
        "align": "Alignment | Alignments",
        "layer": "Layer | Layers",
        "formatting": "Formatting",
        "textColor": "Text Color",
        "font": "Font",
        "textSize": "Text Size",
        "italic": "Italic",
        "bold": "Bold",
        "mine": "Mine",
        "lightTheme": "Light Theme",
        "darkTheme": "Dark Theme",
        "pageIcon": "Page Icon",
        "corporativeEducation": "Corporate Education",
        "interface": "Interface",
        "primaryColor": "Primary Color",
        "fontColor": "Font Color",
        "autoFontColor": "Auto Font Color?",
        "secondColor": "Second Color",
        color: "Color|Colors",
        backgroundColor: "Background Color",
        pick: "Pick",
        left: "Left",
        right: "Right",
        syllabus: "Syllabus",
        backOfCertificate: "Back of Certificate",
        "dateTime": "Date & Time",
        "username": "Username",
        "revertChanges": "Revert Changes",
        "menu": "Menu",
        "for": "for",
        "character": "Character | Characters",
        "example": "Example",
        "privacyPolicy": "Privacy Policy",
        "hi": "Hi",
        "default": "Default",
        "homePage": "Home Page",
        "previousPage": "Previous Page {text}",
        "table": "Table",
        "notRegistered": "Not Registered",
        "both": "Both",
        "automatedNotification": "Automated Notification",
        "externalCertificate": "External Certificate",
        "person": "Person",
        "started": "Started",
        "transfer": "Transfer",
        "autoPlay": "Auto Play",
        "nextActivity": "Next Activity in",
        "extension": "Extension",
        "vocational": "Vocational",
        "academic": "Academic",
        "specialization": "Specialization",
        "describeOthers": "Others (describe)",
        "other": "Other | Others",
        "copied": "Copied",
        "copyLink": "Copy Link",
        "dissociate": "Dissociate",
        "reject": "Reject",
        "newEmail": "New E-mail",
        "confirmEmail": "Confirm E-mail",
        "registered": "Registered",
        "unnamed": "Unnamed",
        "noUnit": "No Unit",
        "setDate": "Set Date",
        "selectAllPage": "Select All from Page",
        "unmarkAllPage": "Unmark All from Page",
        "invertSelection": "Invert Selection from Page",
        "tablePage": "{0}/Page",
        "linkLinkedin": "Link to LinkedIn",
        "shareLinkedin": "Share on LinkedIn",
        "myCertificate": "My Certificate",
        "externalLink": "External Link",
        "remaining": "Remaining",
        "attempts": "Attempts",
        "attemptsRealized": "Attempts Realized",
        "maximumAttempts": "Maximum Attempts",
        "minimumGrade": "Minimum Grade",
        "timeSpent": "Time Spent",
        "hit": "Hit | Hits",
        "miss": "Miss | Misses",
        obtainedScore: 'Obtained Score',
        "viewAnswer": "View Answer Key",
        "attemptsExhausted": "Attempts Exhausted",
        "myAnswers": "My Answer | My Answers",
        "correctAnswers": "Correct Answers",
        "response": "Response",
        "theMedia": "The {object} | The {object}",
        "studyPlan": "Study Plan",
        "contract": "Contract | Contracts",
        "field": "Field",
        "fieldName": "Field Name",
        "data": "Data",
        "congratulations": "Congratulations",
        "goTo": "Go to {0}",
        "conclusion": "Conclusion",
        "educationalInstitution": "Educational institution",
        "typeCourse": "Type of course",
        "import": "Import of {type}",
        "incorrect": "Incorrect | Incorrects",
        "correct": "Correct | Corrects",
        "answerList": "Answer List",
        "note": "Note | Notes | Annotation | Annotations",
        "notification": "Notification | Notifications",
        "notImplemented": "Not implemented",
        "advanced": "Advanced",
        "publicPanel": "Public Panel",
        "replicate": "Replicate Distributor Registrations",
        "panel": "Panel | Panels",
        "logo": "Logo | Logos",
        "banner": "Banner | Banners",
        "pageTitleAndPageDescription": "Page Title and Page Description",
        "loginImage": "Login Image",
        "loginBoxPosition": "Login Box Position",
        "logoutAddress": "Logout Address",
        "privacyPolicyAddress": "Privacy Policy Address",
        "standardTermsOfUse": "Standard Terms of Use",
        "certificates": "Certificates",
        "standardCertificate": "Standard Certificate",
        "remove": "Remove",
        "correctOrIncorrect": "Correct or Incorrect",
        "trueOrFalses": "True or False",
        "unitCode": "Code: {title}",
        "pt": "Portuguese",
        "en": "English",
        "es": "Spanish",
        "expirationDate": "Expires on: {date}",
        "reactions": "{count} Reaction | {count} Reactions",
        "react": "React",
        "reacted": "Reacted",
        "dateExpiration": "Exp. Date",
        "inscriptionDate": "Reg. Date",
        "practice": "Practice",
        "behavior": "Behavior",
        "linkManager": "Link Evaluator Manager",
        "evaluatorManager": "Evaluator Manager",
        "linkTargeting": "Link Targeting",
        "microphone": "Microphone",
        "screen": "Screen",
        "camera": "Camera",
        "uploadType": "Upload Type",
        "typeOfAnswer": "Type of Answer",
        maxScore: "Maximal Score",
        "courseOrTrail": "Course/Path",
        "availableIn": "Available in",
        "heldIn": "Held in",
        "ratedIn": "Rated in",
        "disapproved": "Disapproved",
        "newTry": "New Try",
        "behaviorAssessment": "Behavior Assessments | Behavior Assessment",
        "myAssessments": "My Assessments",
        reviewAssessment: "Review Assessments",
        reviewing: "Reviewing: {title}",
        "closingAssessment": "Closing Assessment",
        "timeUsed": "Time Used",
        "partialGrade": "Partial Grade",
        "landscape": "Landscape",
        "portrait": "Portrait",
        carousel: "Carousel|Carousels",
        slide: "Slide|Slides",
        "answersCorrect": "Correct Answer",
        "answersIncorrect": "Incorrect Answer",
        "answersPartiallyCorrect": "Partially Correct Answer",
        "evaluator": "Evaluator",
        "withdrawal": "Withdrawal",
        "notAnswered": "Not Answered",
        "pendingCorrection": "Pending Correction",
        "assessmentsCorrection": "Assessment Corrections",
        "assessmentId": "Assessment Id",
        "finalConsiderations": "Final Considerations",
        "practicalQuestion": "Practical Question",
        "dissertativeQuestion": "Dissertative Question",
        "responseSended": "Response sent successfully",
        "evaluatorCorrection": "Evaluator Correction",
        "attemptsDescription": "Attempts Realized / Maximum Attempts",
        "evaluatorSet": "Set as Evaluator",
        "disapprovedByGrade": "Disapproved by Grade",
        "introduction": "Introduction",
        "closing": "Closing",
        "showDescription": "Show description",
        "interactiveLink": "Interactive Link",
        "enterLink": "Enter the link",
        "fileSettings": "File Settings",
        "disabledFasForward": "Disable Fast Forward",
        "batchMode": "Apply in batch ?",
    },
    "placeholder": {
        "select": "Select {field}",
        "email": "Enter your e-mail",
        "password": "Enter your password",
        "newPassword": "Enter your new password",
        "confirmPassword": "Confirm your password",
        "uniqueIdentifier": "Enter the unique identifier {name}",
        "learnToday": "What will we learn today?",
        "search": "Search {field}",
        "quickSearch": "Quick Search",
        "searchFuncionality": "Which functionality are you looking to search?",
        "help": "Help",
        "fullname": "Enter Your Full Name",
        "zipCode": "Enter your Zip Code",
        "address": "Enter your Address",
        "enter": "Enter {field}",
        "sectionsAssociate": "Associate plantar sections",
        "communicationsAssociate": "Associate Communications",
        "clickToAssociate": "Click to associate {field}",
        "score": "Enter the score between {0} and {1}",
        "statement": "Enter the question statement to be presented to the student...",
        "feedbackCorrect": "Enter the feedback that will be presented to the student when he/she answers the question correctly...",
        "feedbackIncorrect": "Enter the feedback that will be presented to the student when he/she answers the question incorrectly...",
        "feedbackResponding": "Enter the feedback that will be presented to the student when he/she is responding to the question...",
        "postContent": "Post Content",
        "selectMultiple": "Select one or more {field}",
        "uploadImage": "Upload your image",
        "subject": "Provide a subject for the e-mail.",
        "enterPasteScript": "Enter/paste the additional script here",
        "enterPasteScriptContent": "Enter/paste the script content",
        "enterPasteScriptName": "Enter the script name/identifier (optional)",
        "enterPasteAdress": "Enter/paste the script address (optional)",
        "insertLink": "Enter the banner redirect address. Example: https://www.yoursite.com",
        "confirm": "Confirm {field}",
        "reason": "Please provide the reason for the deletion",
        "answerHere": "Write your answer here",
        "enterNickname": "Enter the nickname",
        "enterDocument": "Enter the document",
        "enterTitle": "Enter the title",
        "enterId": "Enter the ID",
        "enterDescription": "Enter the description",
        "enterObservation": "Enter the observations",
        "enterName": "Enter the name",
        "enterEmail": "Enter the email",
        "enterContent": "Enter the content",
        "enterFantasyName": "Enter the trade name",
        "enterAddress2": "Enter the address complement",
        "enterDistrict": "Enter the neighborhood",
        enterClassification: "Enter the classification",
        "enterCpf": "Enter the CPF",
        "enterCnpj": "Enter the CNPJ",
        "enterCpfCnpj": "Enter the CPF/CNPJ",
        "enterForeignDocument": "Enter the foreign document",
        "enterFullName": "Enter the full name",
        "enterCompanyName": "Enter the company name",
        "enterAlternative": "Enter the alternative {title}",
        "enterAdditionalRecipient": "Enter the additional recipient",
        "enterNumberOfDays": "Enter the number of days",
        "enterNumberOfUsers": "Enter the number of users",
        "enterDiskSpace": "Enter the disk space (GBs)",
        "enterCourseLimit": "Enter the course limit amount",
        "enterTrailsLimit": "Enter the trail limit amount",
        "enterDefaultUrl": "Enter the default URL",
        "enterCustomUrl": "Enter the custom URL",
        "enterSynopsis": "Enter the synopsis",
        "enterCode": "Enter the code",
        "enterSummary": "Enter the summary",
        "enterObjectCode": "Enter the object code",
        "enterInstitution": "Enter the institution",
        "enterLinkBanner": "Enter the web address to which the banner will be redirected",
        "numberDays": "No. of Days",
        "contentSearch": "Content search",
        "suggestions": "Suggest a new media, path, course, or topic you'd like to see on the platform.",
        "noResults": "Didn't find what you were looking for? Send your content suggestions to help us improve your experience on the platform!",
        "enterTheNote": "Enter the note",
    },
    "tooltips": {
        required0: "Required field (even if it is 0)",
        "toggleOrder": "Toggle Order",
        "unreadNotifications": "unread notifications",
        "associateMedias": "Associate videos, audios, images, PDFs, Scorm, etc.",
        "associateCourses": "Associate courses in the step",
        "associateTests": "Associate assessment to the step",
        "synopsis": "Provide a brief description, clarifying what will be covered in the {type} or the most important parts.",
        "detailsDescription": "Here you should provide more details about the objectives of the {type} so that the student can clearly visualize what will be covered.",
        "profile": {
            "calledBy": "By filling in this field, the platform will use it in all communication with you",
            "developSkills": "Based on your selected interests, we will suggest relevant content for you to boost your knowledge and career!",
            "personalData": "The Email and Document fields can only be changed by the platform administration or HR (when integrated).",
            "corporateData": "The data in this section are changed by the HR system (when integrated) or account manager and subscriptions.",
            "academicData": "Enter here the data related to your academic journey and others."
        },
        "originFilter": "To view or filter content from distributors, select the 'Distributor' option in the 'Origin' column.",
        "export": "Enabled, will export all available records. Disabled, will export only the records visible in the table.",
        "contracts": {
            "aliareCode": "This code is automatically loaded when integrated with the Aliare Platform.",
            "observation": "Type here the specific details of the contract necessary for documenting negotiations or other activities.",
            "sectionField": "In this field, you can link one or more sections created by Plantar and share them with the contract, making them visible in the client's learning process.",
            "communicationsField": "In this field, you can link one or more communications created by Plantar and share them with the contract, making them visible in the client's learning."
        },
        "units": {
            "activeAutomation": "Active Automation",
            "childUnit": "Child Units"
        },
        "departments": {
            "observation": "You can provide a text here that assists users about the department."
        },
        "jobsTitles": {
            "developSkills": "By providing competencies, the platform will suggest content that helps the user acquire knowledge related to them."
        },
        "users": {
            "supervisor": "Specify the leader, manager, or immediate superior of the user.",
            "unit": "Specify the business unit to which the user is linked.",
            "permission": "Permissions define the routines the user can access. In the list, click on the Actions menu (Set permission)",
            "receiveEmail": "Enabled, defines that users will receive the emails from their subscriptions.",
            classification: 'Classification that determines the dashboard panel: Student, Administrator or Manager',
            "evaluator": "When enabled, the user will be able to correct assessments that require manual review."
        },
        "repository": {
            "summary": "Provide a brief context about the content. The text will be presented to the student in the creation of courses, paths, and consumption places.",
            "developSkills": "How about linking competencies that this object will support students? You can select one or more competencies, and the platform (if configured) may suggest content for students or positions based on the student's and/or manager's learning objectives.",
            "duration": "This is the duration we found in your media file and will be displayed to the user during playback. If you wish, you can edit it by adjusting the time.",
            "disableActions": "This action cannot be performed with a shared media.",
            "qrcode": "To read this code, you can use a QR reader app or some camera apps on your smartphone.",
            "downloadQrcode": "Download the QR Code",
            "shareQrcode": "Share QR Code"
        },
        "tests": {
            "diagnostic": "Aims to gather information about the students' prior knowledge, skills, and competencies.",
            "reaction": "Aims to measure employees' level of satisfaction with the training and their overall perception of the process.",
            "learning": "Aims to assess the knowledge gained from the applied training.",
            "applicability": "Aims to evaluate whether the student applied what was learned in the training process.",
            "result": "Aims to assess the outcomes obtained from the applied training.",
            "testType": "Based on the chosen type, question and answer models will be presented.",
            "developSkills": "By providing competencies, the platform may suggest assessment that help the user acquire knowledge related to them.",
            requiredScore: "Provide the minimum percentage of correct answers for the student to pass the test.",
            "newAttempt": "Specify if the user can have new attempts to complete the assessment and the rules that will be applied.",
            "numAttempt": "Specify how many attempts the user will have to complete the assessment.",
            "maxQuestions": "Specify the maximum number of questions that will be presented to the user. If there are more than the specified number, they will be loaded in subsequent attempts, if any.",
            "displayAnswers": "If marked, shows the correct and incorrect answers to the user after confirming the selected option.",
            "shuffleQuestions": "Allows shuffling the questions in the assessment, deviating from the inclusion order.",
            "shuffleOptions": "Allows shuffling the question's alternatives, deviating from the inclusion order.",
            "showTimer": "If activated, shows the timer to the user in the assessment.",
            "duration": "Specify the time the user has to complete the assessment, or leave blank for no time limit.",
            "typeQuestions": "Specify the purpose of your question by choosing from the options below.",
            "attachImage": "Allows linking an image to be presented to the user during the assessment.",
            "scale": "Allows the student to choose a number on a scale from 1 to the selected value below.",
            "customFeedback": "Allows including feedback to be presented to the user after correcting the answered option.",
            "behavior": "The Behavior Evaluation is an assessment that takes place after the completion of the course/path. The submission deadline will be defined by your manager and you will be notified when it is available.",
            "submittingBehaviorAssessment": "Deadline for Submitting the Behavior Assessment"
        },
        "courses": {
            "sequence": "Enabled, defines that the user needs to follow the sequence of the defined structure during consumption. Disabled, the user can navigate freely through any content.",
            "duration": "Provide the estimated duration in hours and minutes for the course. To automatically calculate the items inserted, just click on the left button (calculator).",
            "progress": "Specify the percentage of progress required for the generation of the certificate (when associated) and/or to reach the completion status.",
            "daysLimit": "Maximum deadline (in days) for a student to complete the course. If set to 0, there is no control limit.",
            "mandatoryCompletion": "Defines whether the student needs to complete the content within the completion deadline.",
            "certificate": "The certificate can be linked to a course. Once inserted here, it becomes the standard for the course",
            "skills": "How about linking competencies that this object will support students? You can select one or more competencies, and the platform (if configured) may suggest content for students or positions based on the student's and/or manager's learning objectives.",
            "challenges": "How about linking challenges that this object will support students? You can select one or more challenges, and the platform (if configured) may suggest content for students or positions based on the student's and/or manager's learning objectives.",
            "termOfUse": "A term of use can be specific to a course and, when associated, will be displayed at the end of the content.",
            "sections": "Here you can link the course directly to the already registered section. By doing so, the content will already be presented in the learning process for the student."
        },
        "sections": {
            "typeSection": "Choose the type of your section.",
            "environment": "To which environment does the section belong?",
            "useSegmentation": "Choosing to link a segmentation removes previous links with units, departments, and job titles.",
            "useDepartment": "Choosing to link a unit, department, or job title will automatically unlink previous segmentations.",
            "useBoth": "It is not possible to link segmentation and Department, Job Title, and Unit at the same time.",
            "period": "Specify the availability period for the section or leave blank to display it always.",
            "move": "Move to {title}",
            "showDescription": "If enabled, the description will be displayed below the section title on the students' learning screen. Otherwise, it will remain hidden."
        },
        "challenges": {
            "completeWithin": "0 does not have a completion deadline"
        },
        "categories": {
            "types": "Type that will define in which location the category will be listed"
        },
        "segmentations": {
            "observation": "Enter your observations here",
            "operator": "Here you restrict the use of Segmentation to a determined context. Choose 'OR' to serve the user if they meet at least one of the rules. Choose 'AND' to serve the user only if all rules are met.",
            "selectValidate": "At least one link in the Segmentation is required"
        },
        "certificates": {
            "tag": "These tags are automatically replaced by the indicated values",
            "linkedin": "Here you create a new publication on Linkedin from your certificate",
            "profile": "Here you add a new certificate to your profile, including new skills acquired, etc.",
            "unableCertificate": "You have not yet reached the conditions to receive the certificate",
            "emitCertificate": "Issue certificate"
        },
        "permissions": {
            "select": "According to the desired profile, mark in each module the functionalities that users linked to this permission group will be able to access.",
            "validateSelect": "You must select at least one permission!"
        },
        "customizations": {
            "collapsedMenu": "The image will be displayed in the platform's collapsed side menu",
            "menuLogin": "The image will be displayed in the side menu and on the platform's login page",
            "pageIcon": "The page icon is usually displayed on the browser tab",
            "previewChanges": "Preview changes in real-time in the system"
        },
        "automated": {
            "forUsers": "Enabled, defines that the automation will be valid for users registered from the informed date onwards. Note: If marked, date field will be mandatory",
            "forAll": "Enabled, defines that the automation will be valid for all registered users, regardless of their registration date.",
            "setDate": "Date Field: By informing it, the registration will be considered for user registrations greater or equal to the informed date",
            "notifyEmail": "Enabled, defines that users will receive the emails of their subscriptions."
        },
        "details": {
            "limitDays": "Days limit for completion",
            "requiredProgress": "Required progress"
        },
        "termsOfUse": {
            "type": "The type <strong>Platform</strong> can be linked to access parameters so that the user accepts on the first access or whenever the rules change, while the type <strong>Learning</strong> will be linked to a Course or Path defining acceptance criteria or rules in relation to the content.",
            "tags": "To insert a tag into the structure, position the mouse in the desired location and click on the tag to be included. To learn everything about creating terms of use, access the help menu.",
            "displayIn": "It will define whether the <strong>Terms of Use</strong> will appear in the <strong>Introduction</strong> or the <strong>Conclusion</strong> of the course/pathway, or in <strong>both</strong> situations."
        },
        "textDocument": "Text document",
        "presentation": "Presentation",
        "spreadsheet": "Spreadsheet",
        "other": "Other",
        "bookmark": "Bookmark|Bookmarks",
        "multipleContracts": "Apply the configurations for multiple contracts"
    },
    "tabs": {
        "personalData": "Personal Data",
        "corporateData": "Corporate Data",
        "academicData": "Academic Data"
    },
    "modals": {
        "profile": "My Profile",
        "detailsDashboard": "Details {title}",
        "select": "Select",
        "associate": "Associating {title}",
        "details": "Details",
        "adding": "Adding a {title}",
        "editing": "Editing {title}",
        "insertImage": "Insert Image",
        "dissociate": "Dissociate {title}",
        "associateWith": "Associating {title} in {type}",
        "inscriptions": "Inscriptions {type} {title}",
        "newPassword": "Set a New Password",
        "automated": "Automated Journeys in {type} {title}",
        "previewCertificate": "Certificate Preview",
        "sectionsOrder": "Define Sorting of Sections",
        "associations": "Associations with the object {title}",
        "associationsCourses": "Associations with the course {title}",
        "segmentationsUser": "Linked Users",
        "inscriptionsUser": "Student Enrollments: {title}",
        "alterPermission": "Change permission for {num} selected user | Change permission for {num} selected users",
        "addQuestion": "Add Question",
        "editQuestion": "Edit Question",
        "termAgreementTitle": "Term of Acceptance: {title}",
    },
    "validation": {
        "required": "Field required",
        "invalidEmail": "Invalid email",
        "passwordMatch": "Passwords must match",
        "invalid": "Enter a valid {field}",
        "basicCharacters": "Only letters, numbers, and two special characters (_-)",
        "max": "Field cannot exceed {max} characters",
        "min": "Field must have at least {min} characters",
        "range": "Value must be between {0} and {1}",
        "minDuration": "Duration must be at least 1 minute.",
        "minQuestions": "At least one question for the user is required.",
        "nameUsed": "A step with this name already exists",
        "selectOption": "Select at least one option",
        "sameEmail": "The new email cannot be the same as the previous one.",
        "equalsEmail": "Emails must match.",
        "fileSizeExceeds": "File size exceeds the suggested for a good browsing experience. Attach a file up to {maxSize} {unit} in size.",
        "maxValue": "The value cannot be greater than {max}.",
        "maxNote": "The note value cannot be greater than the maximum note.",
        "maxSelected": "Maximum {max} items allowed"
    },
    "feedback": {
        "on": "On",
        "off": "Off",
        "copyToClipboard": "Copied to clipboard",
        "noResults": "No results found",
        "noResultsSearch": "No results were found for the search.",
        "emailSent": "Email sent successfully!",
        "loading": "Loading...",
        "updating": "Updating...",
        "pleaseWait": "Please wait",
        "processingRequest": "Processing request",
        "loadingAchievements": "Loading achievements...",
        "loadingRanking": "Loading ranking...",
        "noFound": "No item found",
        "changeCompany": "Cannot change the company of the contract!",
        "formError": "Check the error(s) in the form",
        "errorLoadObject": "Could not load the information of the {object}! Reload the page and try again.",
        "successRequest": "Request(s) sent successfully!",
        "success": "Success",
        "error": "Error",
        "attention": "Attention!",
        "failedRequest": "Failed to send the request",
        "errorRequest": "Could not send the request(s)",
        "wait": "Wait!",
        "validationFailed": "Validation failed",
        "formatInvalid": "Format not supported for viewing",
        "failedLoadInfo": "Failed to load information",
        "processingData": "Processing data...",
        "await": "Wait...",
        "loadingTest": "Loading assessment information...",
        "somethingWrong": "Oops... Something went wrong",
        "failedLoadItems": "Error loading items...",
        "loadingObjects": "Loading {object}",
        "connectionFail": "It seems that the communication with the service could not be reestablished. Please try again in a few moments.",
        "userNotFound": "User not found, recommended to refresh the page!",
        "noPermissionResource": "You do not have permission to manage this resource",
        "successRequestPassword": "Request sent successfully!",
        "loadingCustomization": "Please wait... Loading system settings",
        "futureBirthday": "The date of birth cannot be in the future",
        "searchPlaceholder": "What are you looking for?",
        "searchInitFeedback": "Explore the contents available on the platform: search among media, courses and trails to find what best meets your learning needs.",
        "navigate": "Navigate",
        "select": "Select",
        "close": "Close",
        "recordedResponse": "Your response has been recorded!",
        "updateAvailable": "Update Available!",
        "updateAvailableMessage": "A new version of the system is available! Click here or reload the page to apply the updates.",
        "loginSettingsMsg": "To access the login settings, click the button below.",
        loginSettingsAccess: {
            title: "Meet our new login screen!",
            message: "We are making some final adjustments, but you can already try the new interface. To access it, click the button below and see the news."
        },
        "suggestionTitle": "Content Suggestion",
        "suggestionSent": "Suggestion sent successfully!",
        "suggestionSentMessage": "Thank you for your suggestion! We value your feedback.",
        cantReact: "You can only react to the content after completion.",
        "inactiveCertificate": "It is not possible to deactivate a certificate linked to a course or track.",
        "finishAssessment": "Assessment completed successfully",
        "manualCorrection": "This question requires manual evaluation.",
        "questionTypeNotSupported": "Unsupported question type!",
        "noOptions": "No options available",
        "errorLoadingAssessment": "Error loading assessment",
        "successCorrection": "Correction saved successfully",
        "attentionResponse": "Respond with attention!",
        "attentionResponseMessage": "An incorrect answer will cancel out the points of a correct answer selected.</br> Make sure to carefully review your choices before proceeding.",
        "uploadAllowed": "This question allows the upload of files for correction",
        "noAlternatives": "Error loading the alternatives, please try again or contact support.",
        "durationOject": "The duration of the object refers to the time necessary to consume the content.",
        "errorLoadingObject": "Error loading...",
        "assessmentNotFound": "Assessment not found, or malformed.",
        pleaseSetScore: "Please inform the correction score in the highlighted question"
    },
    "login": {
        "welcome": "Welcome!",
        "forgotPasswordTitle": "Enter your login email so we can send the password reset link.",
        "returnLogin": "Return to the login screen",
        "sendEmail": "Send",
        "forgotPassword": "Forgot your password?",
        "helpNeeded": "Need help logging in?",
        "talkToUs": "Contact Us",
        "rememberMe": "Remember Me",
        "passwordPlaceholder": "Enter your password",
        "emailPlaceholder": "Enter your e-mail",
        "userFound": "User found...",
        "sessionData": "Registering session data.",
        "loginWith": "Login with account",
        "authenticated": "User successfully authenticated!",
        "loadingInformation": "Loading additional information",
        "accountInformation": "Account Information",
        "userData": "User Data",
        "userPermissions": "User Permissions",
        enterCompanyName: "Enter the company name",
        enterTheProvider: "Enter the provider registered on the platform"
    },
    "recover": {
        "title": "Forgot your password?",
        "hintTitle": "Enter your registered email on the Platform.",
        "hintBody": "We will send a link to reset your password.",
        "submited": "Once you access the link sent to the email <strong>{email}</strong>, you will be redirected to the password reset page.",
        "submitedInstructions": "You will soon receive an email with the link to reset your password."
    },
    "newPassword": {
        "title": "Set your new password",
        "hintTitle": "Enter your new password.",
        "hintBody": "Now you can access the Platform with your new password.",
        "rulesText": "Your New Password must have",
        "recoverAgain": "Reset Password Again",
        "expired": {
            "title": "Invalid/Expired Token",
            "msg": "The activation token is invalid or no longer valid. Please request a new password recovery or contact support."
        },
        "success": {
            "title": "Password reset successfully!",
            "msg": "Now you can access your account again."
        },
        "redirectionMsg": "You will be redirected to the Platform Access screen in a few seconds.",
        "rules": {
            "length": "Minimum of {min} and maximum {max} characters",
            "hasLetters": "Textual characters (abc)",
            "case": "At least one uppercase letter",
            "hasNumbers": "Numeric characters (123)",
            "hasSpecialChars": "Special characters (&$#!)",
            "match": "Both passwords must match"
        },
        "validation": {
            "minMax": "The password must be between {min} and {max} characters.",
            "letters": "The password must contain at least 1 letter.",
            "case": "The password must contain at least 1 uppercase letter.",
            "numbers": "The password must contain at least 1 number.",
            "specialChars": "The password must contain at least 1 special character."
        }
    },
    "alerts": {
        "youIncredible": "You are incredible!",
        "noNotifications": "For now, no new notifications. Enjoy your studies!",
        "dashboardError": "An error occurred while retrieving the dashboard data. Please try again in a few minutes.",
        "canCreateUsers": "Your contract has reached the maximum number of active users! Please contact support.",
        "loadingPartners": "Loading partners, please wait a moment.",
        "errorLoadPartners": "Unable to load the partners linked to your account, please try again in a few moments.",
        "resubscribe": "If you click the <strong>Resubscribe</strong> button, you will lose your already completed content! <br>For more information, contact your manager or platform support",
        "scrollTermsOfUse": "The Accept button will be enabled when you scroll the content to the end.",
        "document": "At this step, a complementary file is required. Click the button below to download.",
        "errorMediaTitle": "Error fetching media information",
        "errorMediaMessage": "There was an error trying to load media information. Try closing and opening again.",
        "termOfUse": "This term of use is linked to the parameters and its structure cannot be changed.",
        "errorBiContent": "There was an error trying to load information from the selected panel. Please try again or check if the registered panel information is correct.",
        errorTermsOfUse: "Could not load the Terms of Use. Please try again later.",
        "blockPlayer": "Since the player is blocked, the actions of increasing the speed of playback and advancing the progress for a time not yet watched will not be used"
    },
    "language": {
        "set": "Set language",
        "change": "Change language"
    },
    "notifications": {
        "activityHistory": "Activity History",
        "alertEnable": "Click the button below to enable notifications in your browser.",
        "enable": "Enable notifications",
        "unsupportedTitle": "Instant notifications disabled!",
        "unsupportedBody": "Your browser does not support notifications, or they are disabled.",
        "history": "Notification History",
        "uploadHistory": "Upload History",
        "unread": "Just unread",
        "markSelectionRead": "Mark Selection as read"
    },
    "menu": {
        "learning": "Learning",
        "management": "Management",
        "myProgress": "My Progress",
        "dashboard": "Dashboard",
        "myPanel": "My Panel",
        "bi": "BI",
        "externalCertificates": "External Certificates",
        "accounts": "Accounts",
        "companies": "Companies",
        "contracts": "Contracts",
        "units": "Units",
        "departments": "Departments",
        "jobsTitles": "Job Titles",
        "users": "Users",
        "content": "Content",
        "repository": "Repository",
        "tests": "Assessments",
        "courses": "Courses",
        "trails": "Paths",
        "sections": "Sections",
        "manageExternalCertificates": "Manage External Certificates",
        "communications": "Communications",
        "communication": "Communication",
        "automatedNotifications": "Automated Notifications",
        "gamification": "Gamification",
        "journey": "Journey",
        "challenges": "Challenges",
        "settings": "Settings",
        "customizations": "Customizations",
        "skills": "Competencies",
        "permissions": "Permissions",
        "categories": "Categories",
        "certificates": "Certificates",
        "termsOfUse": "Terms of Use",
        "segmentations": "Segmentations",
        "rules": "Rules",
        "myContract": "My Contract",
        "notificationHistory": "Notifications History",
        "uploadHistory": "Uploads History",
        "notifications": "Notifications",
        biPanels: "BI Panel|BI Panels",
        "importHistory": "Import History"
    },
    "topbar": {
        profile: "Profile",
        settings: "Settings",
        termsAndConditions: "Terms and Conditions",
        changeCompany: "Change Company",
        logout: "Logout"
    },
    "mainContent": {
        "sections": {
            "keepLearning": "Keep Learning",
            "suggestedForYou": "Suggested For You",
            "favorites": "Your favorites will be seen here! <br> To favorite, just mark the icon in the top corner present in the content",
            "noFound": "We did not locate any result in <strong>{text}!</strong> How about trying new filters or changing tabs?",
            "startLearning": "How about starting your learning journey? <strong>Click here</strong> and find the ideal content for you!",
            "emptySection": "You do not have any active section.<br>Start inserting<br>content now!Click here!<br>"
        },
        "export": {
            "moveColumns": "Move all columns?",
            "columnsExported": "Columns to be exported",
            "dragColumns": "Drag the columns here",
            "exportAll": "Export all data?",
            "help": "Drag all available columns you want to export to the 'Columns to be exported' area on the right.",
            "availableColumns": "Available columns",
            "removeColumns": "Remove all columns"
        },
        "units": {
            "addChild": "Add Child Unit",
            "helpTitle": "Create your organizational structure!",
            "createOther": "You can create other <strong>units</strong> within an existing one.",
            "justClick": "Just Click",
            "orCreate": "or create a new structure at the root by clicking"
        },
        "users": {
            "import": {
                "alerts": {
                    "initial": {
                        "title": "Guidelines for Batch Import",
                        "message": "For batch import, the file must be in .xls or .xlsx format. To proceed with the import, you need to download the template file, fill it in, and send it."
                    },
                    "valid": {
                        "title": "The file was validated successfully!",
                        "message": "Click on 'import data' to complete the import scheduling."
                    },
                    "finish": {
                        "title": "Your data have been scheduled successfully!",
                        "message": "The data are scheduled for import. Please check the user list after 10 minutes to follow the progress."
                    },
                    "processing": {
                        "title": "Validating your file!",
                        "message": "Select the parameters and click on 'Import Data' to finish."
                    },
                    "failed": {
                        "title": "We couldn't validate your file! Check if it's the correct size and format, and try again.",
                        "message": "For more details about the import"
                    }
                },
                "feedback": {
                    "initial": {
                        "title": "Easily import your data",
                        "message": "Batch registration offers more convenience and speed for you, allowing data import for different people at the same time."
                    },
                    "processing": {
                        "title": "We are validating your file...",
                        "message": "This step is to ensure everything is correct with the file before being imported."
                    },
                    "failed": {
                        "title": "Import Summary"
                    },
                    "start": {
                        "title": "The import is underway...",
                        "message": "The import will be completed shortly."
                    }
                },
                "importTemplate": "Don't have the template to import users in batch?",
                "download": "Download template",
                "log": "Download the log",
                "dragdrop": "Drag and drop the .xls / .xlsx file here or"
            },
            "updateEmail": "E-mail Update",
            "triggerEmail": "Trigger subscription email"
        },
        "inscriptions": {
            "import": {
                "feedback": {
                    "initial": {
                        "message": "Batch registration offers more convenience and speed for you, allowing the import of data for different registrations at the same time."
                    },
                },
                "importTemplate": "Don't have the template to import batch registrations?",
            },
        },
        "repository": {
            "dragDrop": "Select or drag a file to this area.<br><br> Considering the user's experience when watching videos, we recommend that your files do not exceed a size of 1 GB.",
            "generateQr": "Generate QR Code",
            "insertLink": "Insert Link"
        },
        "automatedNotifications": {
            "events": {
                "notAccessEnvironment": "Did not access the platform during a defined period of days",
                "notStartedCourse": "Did not start the course during a defined period of days",
                "notStartedTrail": "Did not start the path during a defined period of days",
                "expireCourse": "Course expiration approaching",
                "expireTrail": "Path expiration approaching"
            },
            "sendingInterval": {
                "once": "Only once",
                "day": "Once a day",
                "week": "Once a week",
                "month": "Once a month"
            },
            "template": {
                "notAccessEnvironment": "<p>We noticed it's been a while since your last visit to our platform. We know how important it is to stay updated and constantly learning.</p><p>We're here to remind you that we have a wide variety of courses and paths available to help in your professional growth.</p><p>Take the opportunity to explore new content and continue your learning journey.</p><p>We hope to see you again soon!</p>",
                "notStartedCourse": "<p>We want to encourage you to start the course or path available to you on our platform. We would like to remind you that you have received an enrollment, but it is still pending. Access the platform and take the first step in your learning journey!</p>",
                "expireCourse": "<p>We hope you are enjoying the course or path you are taking with us! We would like to remind you that the expiration date of your course or path is approaching. Take this moment to complete the course or path within the established time frame and continue investing in your professional development. We are excited to see you achieve your goals and hope to see you again soon!</p>"
            },
            "subject": {
                "notAccessEnvironment": "We miss you! Return to the platform and continue learning",
                "notStartedCourse": "Don't miss the chance to learn! Start your course/path now",
                "expireCourse": "Reminder: Your course/path is almost at an end"
            },
            "needHelp": "<strong>Need help?</strong> Our support team is ready to support you, just click on the help icon.",
            "previewFooter": "This is an automatic email sent by Plantar Education | www.plantareducation.com",
            "tags": {
                "typeContent": "Content Type",
                "limitDate": "Course/Path Expiration Date",
                "unit": "User's Business Unit Name",
                "department": "User's Department Name",
                "jobTitle": "User's Job Title",
                "dateTime": "System Date and Time"
            }
        },
        "challenges": {
            "environment": "In which environment will the challenge be applied?",
            "typeContent": "For which type of content?"
        },
        "certificates": {
            "student": "Student's Name",
            "cpf": "Student's CPF",
            "jobTitle": "Student's Job Title",
            "content": "Name of the course/path",
            "completed": "Date the course/path was completed",
            "init": "Date the course/path was started",
            "duration": "Amount of hours of the course/path",
            "certificateId": "Unique identifier of the certificate",
            "newContent": "New Content",
            "objectiveCompleted": "<p><strong>Congratulations!</strong> You have completed the objectives and deserve this certificate!</p><p>You can view and download your certificate by clicking the button below!</p>",
            "certificateCompletion": "Certificate of Completion",
            "sentTestTitle": "Thank you! Your assessment has been received!",
            "sentTestMessage": "Your feedback is very important for us to continuously improve.",
            "completedProgress": "You have reached the necessary progress and/or achievement for this content",
            "canReturn": "You can return and review anytime you want also using the area of",
            "searchContent": "or search for new content by accessing the environment",
            "generateNow": "How about generating your certificate right now",
            "notAccept": "To generate the certificate and it is necessary to accept the terms of acceptance",
        },
        "customizations": {
            "homePage": "Display on Home Page",
            "collapsedMenu": "Collapsed Menu",
            "menuLogin": "Menu and Login",
            "generalSettings": "General Settings",
            "defaultCertificate": "Default Certificate",
            "logoutAddress": "Logout Address",
            "policyAdress": "Privacy Policy Address",
            "backgroundImage": "Background Image(s)",
            "boxPosition": "Box Position",
            "logos": "Logos and Favicon",
            "pageTitle": "Page Title and Description",
            "integrations": "Integrations",
            "newScript": "New Script",
            "downloadPdf": "Allow download of PDF documents",
            "addWatermark": "Add watermark to PDF documents",
            "menuLogoText": "Insert the image for the internal menu logo in .JPG or .PNG format. We recommend images with dimensions of <strong>90 x 60</strong> pixels. Attach a file up to <strong>100 kb</strong> in size.",
            "loginLogoText": "Insert the image for the external login in .JPG or .PNG format. We recommend images with dimensions of <strong>150 x 150</strong> pixels. Attach a file up to <strong>100 kb</strong> in size.",
            "logo": "Logo <strong>{type}</strong>",
            "pageIconHelp": "Insert the image for the page icon in .PNG format to preserve the transparency of the background. We recommend images with dimensions of <strong>48 x 48</strong> pixels. Attach a file up to <strong>100 kb</strong> in size.",
            "pageIcon": "Page Icon <strong>",
            "internalBanners": "Internal Banners",
            clickToAssciateCertificateCourse: "Click to associate a certificate to the course",
            clickToAssciateCertificateTrail: "Click to associate a certificate to the trail",
            bannerLearning: "Banner for Learning Page",
            bannerPublicAlert: "This banner was sent by a distributor, so only users with distributor access can change it.",
            "bannerLogin": "Background Image for Login Screen",
            "bannerNewTab": "Open link in new tab?",
            login: {
                title: "Login page settings",
                position: "Login box position",
                newCarousel: "New carousel",
                carouselPublicAlert: "This carousel was sent by a distributor, so only users with distributor access can change it.",
                mediaUpload: "Media Upload",
                removeCarousel: {
                    title: "Do you really want to delete this item?",
                    message: "This action is irreversible and the item will be permanently removed. If you prefer, you can choose to disable it instead of deleting it."
                }
            }
        },
        "cookies": {
            "title": "Cookie Consent",
            "content": "We use cookies and similar technologies to improve your experience, according to our",
            "continue": "By continuing to browse, you agree to these conditions."
        },
        "partner": {
            "access": "Which company do you want to access?",
            "notSeeAgain": "I do not wish to see this screen again!",
            "currentCompany": "Current Company",
            "otherCompanies": "Other Companies",
            "loadList": "Wait a moment while we load the companies linked to your account.",
            "search": "The search for \"{text}\" did not yield any results.",
            "differentTerms": "Try searching with different terms. If you need assistance, please contact our support.",
            "loadingOrganization": "Loading organization data",
            "defaultCompany": "This is my default company"
        },
        "notAllowed": {
            "restrictedArea": "Restricted Area!",
            "noPermission": "You do not have the necessary permissions to access this page."
        },
        "notFound": {
            "title": "Page not found!",
            "content": "The page you tried to enter may not exist or was moved."
        },
        "learning": {
            "banner": "How about inserting a banner here?<br>Click to access the settings!"
        },
        "classRoom": {
            "exitClassRoom": "Exit the classroom",
            "unavailableContent": "Content unavailable",
            "expired": "The time for your content's completion expired on: <strong>{date}</strong>",
            "contactNewSubscribe": "Contact your manager or the platform support to request a new subscription",
            "testInfo": "Assessment Information",
            "navigateQuestions": "Navigate through questions",
            "remainingQuestions": "Remaining questions",
            "timeOver": "<div>The defined time for completing this assessment is over!</div><div>The calculation for your approval or disapproval will be based on the responses submitted.</div>",
            "testTips": "We have some tips for you to take your assessment without any problems",
            "tips": "<li>Be aware of the duration time if the assessment has a time limit</li><li>Your response will only be sent when you click on Send Response</li><li>After sending the response you will not be able to change the selected alternative</li>",
            "reactionTestTips": "<li>Your response will only be sent when you click on Send Response</li><li>After sending the response you will not be able to change the selected alternative</li><li>After finishing or leaving the assessment, you will not be able to enter and do it again</li>",
            "completedItems": "completed items",
            "approved": "You have been approved",
            "disapproved": "You did not achieve the required grade",
            "pendingCorrection": "Congratulations on completing your assessment! 🎉",
            "pendingCorrectionInformation": "Now, your answers will be sent for review by an evaluator, who will analyze the practical or essay questions to record your final grade.</br></br>Once the review is complete, your grade will be released. If your assessment is linked to a certificate, it will be available after this stage.</br></br>Thank you for your dedication and happy studying! 😊"
        },
        "externalCertificates": {
            "approved": {
                "title": "Your request has been approved",
                "message": "Your course has been approved and registered"
            },
            "denied": {
                "title": "Your request has been denied",
                "denyPosition": "The certificate is not valid for the position held",
                "denyInvalid": "The certificate informed is invalid",
                "denyIllegible": "The certificate informed is illegible",
                "denyOther": "The certificate informed was denied, see the reason below",
                "help": "You can try to edit the certificate and send it again for approval"
            },
            "pending": {
                "title": "Pending",
                "message": "Waiting for validation and approval"
            },
            "incompatibleRole": "Incompatible with the role",
            "invalidCertificate": "Invalid certificate",
            "illegibleCertificate": "Illegible certificate",
            "approvalRequest": "Approval request: {title}",
            "denyReason": "Write the reason for denial",
            "addCertificate": "Add your certificate",
            "dropFile": "<strong>Locate your file or drag and drop here</strong> to send it. It should be in <strong>png</strong>, <strong>jpg</strong>, or <strong>pdf</strong> format, and must be <strong>up to 2 Mb</strong>."
        },
        "automated": {
            "forUsers": "For users created from a defined date",
            "forAll": "For all users",
            "notifyEmail": "Trigger subscription email"
        },
        "termsOfUse": {
            "structure": "Structure of the Terms of Use",
            "user": "Logged in user's name",
            "unit": "Name of the user's business unit",
            "department": "Name of the user's department",
            "jobTitle": "Job title of the logged in user",
            "dateTime": "System date and time",
            "date": "System date",
            "hour": "System time",
            "defaultContent": "Provide the terms of use, which will be presented to users on the Platform."
        },
        "notes": {
            "title": "My notes",
            "view": "View My Notes",
            "insert": "Insert My Note"
        }
    },
    "column": {
        title: "Title",
        noTitle: "No Title",
        "type": "Type",
        "situation": "Situation",
        "status": "Status",
        "expired": "Expired",
        "required": "Required",
        "origin": "Origin",
        "subscribedAt": "Subscribed At",
        "completedAt": "Completed At",
        "student": "Student",
        "institution": "Institution",
        "createdAt": "Created At",
        "requestStatus": "Request Status",
        "description": "Description",
        "workload": "Workload",
        "duration": "Duration",
        "startDate": "Start Date",
        "endDate": "End Date",
        "certificateUrl": "Certificate URL",
        "credentialCode": "Credential Code",
        "credentialUrl": "Credential Link",
        "identifier": "Identifier",
        "inscriptionOrigin": "Inscription Origin",
        "name": "Name",
        "onGoing": "On Going",
        "trainingHours": "Training Hours",
        "access": "Access | Accesses",
        "timeAccess": "Date and Time of Access",
        "consumedContent": "Consumed Content",
        "userAccess": "User Access | User Accesses",
        "numAccess": "Number of Accesses | Number of Accesses",
        "lastAccess": "Last Access | Last Accesses",
        "completedDate": "Completion Date",
        "contentName": "Content Name",
        "inscriptionDate": "Inscription Date | Inscription Dates",
        "consumption": "Consumption",
        "unit": "Unit | Units",
        "phone": "Phone Number",
        "accountId": "Account ID",
        "classification": "Customer Classification",
        "contractDate": "Contract Date",
        "qtyUsers": "Number of Users",
        "diskSpace": "Disk Space {0}",
        "courseLimit": "Max Number of Courses",
        "trailLimit": "Max Number of Tails",
        "defaultUrl": "Default URL",
        "communicationEmail": "Communication Email",
        "contactPersonName": "Contact Person Name",
        "contactPersonEmail": "Contact Person Email",
        "contactPersonPhone": "Contact Person Phone",
        "companyType": "Company Type",
        "managerName": "Manager Name",
        "managerDocument": "Manager Document",
        "managerEmail": "Manager Email",
        "observation": "Observation",
        "customUrl": "Custom URL",
        "parentUnit": "Parent Unit",
        "fileType": "File Type",
        "score": "Approval Score",
        "showTimer": "Show Timer",
        "canRetry": "Allow Retries",
        "maxRetries": "Maximum Retries",
        "maxQuestions": "Maximum Questions",
        "shuffleQuestions": "Shuffle Questions",
        "shuffleOptions": "Shuffle Options",
        "displayAnswers": "Display Correct and Incorrect Answers",
        "coverLink": "Cover Link",
        "environment": "Environment",
        "sharedSection": "Shared Section",
        "bannerLink": "Banner Link",
        "redirectLink": "Redirect Link",
        "initialPubDate": "Initial Publication Date",
        "finalPubDate": "Final Publication Date",
        "period": "Period",
        "alwaysDisplay": "Always Display",
        "supervisorCopied": "Supervisor Copied",
        "version": "Version",
        "registrationDate": "Registration Date",
        "justification": "Justification",
        "product": "Product | Products",
        "inscription": "Inscription | Inscriptions",
        "dateDispatch": "Date of Dispatch",
        "displayIn": "Display In",
    },
    "months": {
        "jan": "January",
        "feb": "February",
        "mar": "March",
        "apr": "April",
        "may": "May",
        "jun": "June",
        "jul": "July",
        "aug": "August",
        "sep": "September",
        "oct": "October",
        "nov": "November",
        "dec": "December"
    },
    "gamification": {
        "title": "Follow your journey",
        "points": "Points",
        "ranking": "Overall Ranking",
        "myJourney": "My Journey",
        "conquered": "Conquered on",
        "latestAchievements": "Latest Achievements",
        "status": {
            "error": "Unable to retrieve the list of achievements.",
            "emptyData": "No data to display yet."
        },
        "fullJourney": "My complete journey",
        "earnMorePoints": "Earn more points",
        "place": "{position}th place",
        "history": "Achievement history",
        "moorningSun": "Good morning to the sun",
        "nightMoon": "Good night to the moon",
        "nightStars": "Good night to the stars",
        "won": "You won!",
        "getPoints": "Get points",
        "achievementReceived": "Check out this achievement I received!",
        "overallRanking": "Overall Ranking",
        "unitRanking": "Unit Ranking"
    },
    "helpers": {
        "profile": "Here you can define some segmentation rules for content by skills, update your personal and professional data, and choose that special photo for your profile (in .JPG or .PNG format, with <strong>200 x 200</strong> pixels). You can also attach a file up to <strong>1 MB</strong> in size.",
        "repository": "Here, you can insert learning objects in the form of External Link, which provides access to video contents from platforms like Vimeo, Youtube, among other links, including access to meeting rooms on Teams, Zoom, and Google Meet.",
        "cardImage": "Insert the card image for the object in .JPEG, .JPG, .JFIF, .WEBP, .GIF, or .PNG formats.",
        "recommended": "We recommend images with <strong>{dimension}</strong> pixels. Attach a file with a size of up to <strong>{size}</strong>.",
        "objectDetails": "Provide details for the learning object.",
        "associateQuestions": "Now let's associate the questions!",
        "structureQuestions": "Here, you can define the structure of your questions and link them to the assessment.",
        "courseDetails": "Provide details of the course!",
        "associateObjects": "Now, let's associate objects to the course.",
        "createSteps": "How about creating the steps for your path?",
        "structureObject": "Here, you will structure your course with objects already inserted in the repository and additional materials for the students.",
        "stepsCourse": "In this step, you can establish the main rules for content consumption, segmentation, and distribution. How about accessing the <strong>Help</strong> menu and learning everything about each parameter?",
        "stepsTrail": "In the steps, you can associate learning objects, courses, and additional materials",
        "sectionCommunication": "Insert the image for the student section area in .JPG or .PNG formats. We recommend images with dimensions of <strong>1152 x 335</strong> pixels. Attach a file with a maximum size of <strong>3 MB</strong>.",
        "associateContent": "Now, let's associate the content!",
        "associateStructure": "Here, you define the structure of your section by adding courses or paths for student access.",
        "mainRules": "In this step, you can establish the main rules for content consumption, segmentation, and distribution",
        "dontWorry": "Don't worry about filling in the filters; when left blank, they will show everything!",
        "postImage": "<strong>Locate your file or drag and drop it here</strong> so it can be uploaded. It must be in <strong>png</strong> or <strong>jpg</strong> format, and must be <strong>no more than 2 Mb and 800x600 pixels.</strong>",
        "associateRules": "Now let's associate rules to your challenge.",
        "structureAssocaiteRules": "Here you will create the structure of your challenges using existing rules and then link them to courses and paths.",
        "challengesGeneralRules": "In this step, you can define the main rules for challenges and link them to courses and paths. Questions? Just access the Help menu and learn everything about each parameter!",
        "bannerLearning": "Insert the image for the internal student area in .JPG or .PNG format. We recommend images with dimensions of <strong>1156 x 384</strong> pixels. Attach a file up to <strong>3 MB</strong> in size.",
        bannerLogin: "Insert the image for the user's external login area in .JPG or .PNG format. We recommend images with dimensions of {dimensions} pixels. Attach a file up to {fileSize} in size.",
        "readQrcode": "To read this code, you can use a QR reader app or some camera apps on your smartphone.",
        "automatedTitle": "Now let's create the journeys for your {object} | Now let's create the journeys for your {object}",
        "automatedDescription": "Here you can create automated journeys for your users' subscriptions. How about clicking the Associate button and starting right now?",
        "automatedRules": "In this step, you can define the main rules for your automation. How about accessing the Help menu and learning everything about each parameter?",
        "stageNoItems": "This stage does not have linked items",
        "notAvailable": "you are trying to access is no longer available for viewing, please contact the administrator",
        "noNotes": "Here you can view, edit, and delete notes from your classes. They will help you quickly return to interesting or challenging moments later.",
        "hideSystemChat": "Hide Plantar Education help chat",
        "hideSystemChatTT": "Displays the Plantar help chat when clicking the help icon in the top menu, or the text on the login screen.",
        "sendObject": "Send your object",
        "acceptImage": "Drag and drop here, or browse for your file. It must be in Image format and have a maximum size of 1 GB.",
        "acceptItems": "Drag and drop here, or browse for your file. It must be in Video, PDF, Audio, Image, Document, or Scorm format and have a maximum size of 1 GB."
    },
    "noContent": {
        "companies": {
            "title": "The first step is to create the companies!",
            "message": "You need to create companies (individuals or legal entities) that will be included in the sequence of your contracts, understood. Click the button below and start entering records.",
            "buttonText": "New Company"
        },
        "contracts": {
            "title": "Let's create your Contracts?!",
            "message": "You can create and monitor your contracts individually by client: consumption, contracted modules, and provide support for client processes. How about clicking the button below and starting right now?",
            "buttonText": "New Contract"
        },
        "units": {
            "title": "Let's create your organizational structure?",
            "message": "You can build the structure of your unit with up to 5 levels. How about clicking the button below and starting right now?",
            "buttonText": "New Unit"
        },
        "departments": {
            "title": "Let's create your departments?",
            "message": "Here, you can create departments to link to users, facilitating management. How about clicking the button below and starting right now?",
            "buttonText": "New Department"
        },
        "jobsTitles": {
            "title": "Let's create your job titles?",
            "message": "Here, you can create job titles to link to users, facilitating management. How about clicking the button below and starting right now?",
            "buttonText": "New Job Title"
        },
        "users": {
            "title": "Let's create users and accesses?",
            "message": "Here, you can create your users and define access standards. How about clicking the button below and starting right now?",
            "buttonText": "New User"
        },
        "repository": {
            "title": "Let's create your objects?",
            "message": "How about keeping all your learning objects in one place? Here, you can categorize them by types, etc., and use them later by associating them with courses and paths. Click the button below and start adding objects.",
            "buttonText": "New Object"
        },
        "tests": {
            "title": "Let's create your assessment?",
            "message": "Here, you can create assessments, link them to courses and paths, and even direct them to the desired audience. How about clicking the button below and starting right now?",
            "buttonText": "New assessment"
        },
        "courses": {
            "title": "Let's create your courses?",
            "message": "Now that you have structured learning objectives in the repository, you can start creating your courses quickly. How about starting right now!",
            "buttonText": "New Course"
        },
        "trails": {
            "title": "Let's create your paths?",
            "message": "Start your path by inserting general information, then provide a synopsis. Next, you can create steps and associate learning objects. How about starting right now?",
            "buttonText": "New Path"
        },
        "sections": {
            "title": "Let's create your Sections?",
            "message": "Here you can create Sections, link courses, paths, or communication, and even direct them to the desired audience. How about clicking the button below and starting right now?",
            "buttonText": "New Section"
        },
        "manageCertificates": {
            "title": "Let's manage External Certificates?",
            "message": "Here, you can approve or deny requests for External Certificates sent by users of the platform."
        },
        "communication": {
            "title": "Let's create your communications?",
            "message": "Here you can create featured communications, facilitating communication with users. How about clicking the button below and starting right now?",
            "buttonText": "New Communication"
        },
        "notifications": {
            "title": "Let's create your notifications?",
            "message": "Here you can create automated notifications, which will be sent based on the events defined when registering. How about clicking the button below and starting right now?",
            "buttonText": "New Notification"
        },
        "challenges": {
            "title": "Let's create your challenges?",
            "message": "Here you can use the available rules to create your own challenges and direct them to courses and paths. How about clicking the button below and starting right now?",
            "buttonText": "New Challenge"
        },
        "skills": {
            "title": "Let's create your competencies?",
            "message": "Here you can create competencies to link in management and in contents. How about clicking the button below and starting right now?",
            "buttonText": "New Competency"
        },
        "categories": {
            "title": "Let's create your categories?",
            "message": "Here you can create categories to link to the repository, courses, and paths, facilitating management. How about clicking the button below and starting right now?",
            "buttonText": "New Category"
        },
        "segmentations": {
            "title": "Let's create your segmentations?",
            "message": "Here you can create your segmentations to link to users, facilitating management. How about clicking the button below and starting right now?",
            "buttonText": "New Segmentation"
        },
        "certificates": {
            "title": "Let's create your certificates?",
            "message": "How about creating personalized certificates for your courses and paths? Click the button below and start right now!",
            "buttonText": "New Certificate"
        },
        "permissions": {
            "title": "You can create Permission Groups!",
            "message": "Here you can create your own access profiles, defining which routines your users can access, by creating permission groups. How about clicking here and starting right now?",
            "buttonText": "New Permission Group"
        },
        "customizations": {
            "title": "Let's customize your environment?",
            "message": "Here you can configure your environment. How about giving it your visual identity, including banners, colors, logo, among others?",
            "buttonText": "Customize Environment"
        },
        "termsOfUse": {
            "title": "Let's create your terms of use?!",
            "message": "Here you can create usage terms and link them to the platform, courses, or paths. How about clicking the button below and starting right now?",
            "buttonText": "New Term of Use"
        },
        "externalCertificates": {
            "title": "Let's create your External Certificates?",
            "message": "Here you can input your certificates issued on other platforms. How about clicking the button below and starting right away?",
            "buttonText": "New external certificate"
        }
    },
    "dialogs": {
        "ops": "Oops!",
        "confirmation": "Confirmation!",
        "setClientMode": {
            "title": "Environment Change!",
            "message": "<p>You are about to access the client's environment for the selected contract.</p> Attention! All actions (layout changes, registrations, maintenance, etc.) will affect the client's production environment: <strong>{companyName}</strong>. <p>Are you sure you want to continue?</p>"
        },
        "delete": {
            "title": "Attention",
            "message": "<p>You are about to remove {type}: <strong>{name}</strong></p><p>This action cannot be undone!</p>"
        },
        "recoverPassword": {
            "usersWarningRequest": "Could not send request(s) for {0} user(s)",
            "usersErrorRequest": "Could not send request(s) for <strong>{0}</strong> user(s): <strong>{1}</strong>."
        },
        "removePermissions": {
            "title": "Remove permissions",
            "message": "Do you want to remove the permissions of the selected user? | Do you want to remove the permissions of the selected users?"
        },
        "recoveryPassword": {
            "message": "Sending password recovery request(s)..."
        },
        "executeManualNotification": {
            "title": "Attention",
            "message": "<p>You are about to manually execute the notification flow. This will send emails to affected students.</p></br><p>Are you sure you want to proceed?</p>"
        },
        "tests": {
            "moreThanTrue": "<p>There are more than one option marked as true. If you change the question type to <strong>objective</strong>, all options will be marked as 'false'.<p><p>Are you sure you want to continue?</p>",
            "removeQuestion": "Do you really want to delete the indicated question?",
            "removeSkills": "Do you really want to remove <strong>{name}</strong>?"
        },
        "sections": {
            "changeSegmentation": "<p>This section already has defined segmentations. Continuing will remove these links.</p><p>Do you want to continue?</p>",
            "changeDepartment": "<p>This section already has a unit, department, or job title defined. Continuing will remove these links.</p><p>Do you want to continue?</p>"
        },
        "challenges": {
            "deleteRule": "Do you really want to delete the indicated rule?"
        },
        "certificates": {
            "remove": "Do you really want to remove this element?"
        },
        "customizations": {
            "changeTermOfUse": "<p>You have changed the default terms of use, so all students will be notified on their next entry to accept the new terms.</p><p>Do you want to continue?</p>",
            "removeBanner": "<p>Are you sure you want to remove the banner? <strong>This action cannot be undone</strong>!</p><p>However, if you wish, you can insert one or more banners using this routine.</p>",
            "removeBgLogin": "<p>This background image looked nice :(.</p><p>Can I really say goodbye to it?...<p><strong>Press 'confirm', and the image will go away</strong>.</p>"
        },
        "partner": {
            "title": "Set as default company",
            "message": "<p>Do you really want to set the company: <strong>{name}</strong> as default?</p>"
        },
        "externalCertificates": {
            "title": "Approve external certificate?",
            "message": "<p>Do you want to approve this course? The student will be notified immediately</p>"
        },
        "inscriptions": {
            "removeMoreThanOne": "Do you want to cancel <strong>{count}</strong> selected subscriptions?",
            "removeOne": "Do you want to cancel the subscription of the selected student?"
        },
        "automated": {
            "inscriptions": "<p>If there are student subscriptions linked to this course or path, they will be cancelled upon confirmation.</p>Do you want to continue?<p>"
        },
        "repository": {
            "fileExists": "There is already a file attached, do you want to proceed? (If yes, the file will be detached)",
            "linkExists": "There is already a link inserted, do you want to proceed? (If yes, the link will be removed)",
            "removeFile": "Do you really want to remove the current media?"
        }
    },
    "toasts": {
        "failedLoadingMap": "Unable to load the map of states! Reload the page and try again",
        "invalidFile": "Invalid file format",
        "acceptFiles": "The accepted formats are {0}",
        "updateSuccess": "Record updated successfully",
        "createSuccess": "Record created successfully",
        "removeSuccess": "Record removed successfully",
        "sentSuccess": "Record sent successfully",
        "contactManager": "Please contact your manager",
        "removePermissions": {
            "sending": "Removing permissions",
            "successTitle": "Permissions removed successfully!",
            "successMessage": "The permissions have been removed"
        },
        "repository": {
            "objectNoFile": "It is not possible to save an object without a file!",
            "objectNoLink": "It is not possible to save an object without inserting a valid link!",
            "scormType": "It is not possible to save a Scorm without specifying its type!",
            "cancelTransfer": "Cancelled by the user",
            "uploadCover": "of the cover image",
            "invalidQrcode": "Invalid file link to generate QRCode!"
        },
        "tests": {
            "trueOrFalse": "It is possible to insert only two options in True/False questions.",
            "noDescription": "There are options without descriptions!",
            "oneTrue": "At least one correct option is required!",
            "addOption": "An option is required",
            "notSave": "The assessment cannot be saved without any questions!"
        },
        "communication": {
            "requiredPost": "A post is required!",
            "requiredTitle": "A title or image in the post is required!"
        },
        "challenges": {
            "addOption": "An item is required to advance to the next step!",
            "points": "The score must be greater than zero!",
            "itemToCreate": "An item is required to create the challenge!"
        },
        "skills": {
            "observation": "Provide a summary about the competency you are including."
        },
        "login": {
            "errorLoadingInfo": "Error loading additional information"
        },
        "sections": {
            "contractLinked": "Contracts linked successfully!",
            "sortingSaved": "Sorting saved successfully!"
        },
        "users": {
            "updatedEmail": "E-mail updated successfully!",
            "defaultAccount": "Default account set successfully!",
            "accessValidated": "Your access has been validated!",
            "resetPassword": "Password reset!",
            "welcome": "Welcome!",
            "updatedPassword": "Your password has been updated successfully!"
        },
        "inscriptions": {
            "subscribe": "{0} students subscribed successfully!",
            "unsubscribe": "{0} subscriptions cancelled successfully!"
        },
        "system": {
            "saveSuccess": "Parameters registered successfully"
        },
        "classRoom": {
            "giveUp": "<p>By exiting the assessment you will lose the ongoing progress. If your assessment has only one attempt it will be considered as failed in this assessment.</p>",
            "looseInProgress": "<p>By exiting the assessment you will lose the ongoing progress. If your assessment has only one attempt it will be considered <strong>failed</strong> in this assessment.</p> Do you want to continue?",
            "notReturn": "<p>By exiting the assessment you will not be able to return to perform it. If you filled only part of the alternatives, the answers will be disregarded.</p>Do you want to continue?",
            "notAnswerReaction": "<p></p>Do not want to answer the reaction assessment? <strong>No problem! By clicking on confirm we will mark this assessment as completed. Agreed?</strong></p>"
        },
        "externalCertificates": {
            "selectJustification": "A justification is required!"
        },
        "dashboard": {
            "generatingErrorTitle": "Error generating certificate",
            "generatingErrorMessage": "It was not possible to generate the certificate, please try again later"
        },
        "details": {
            "registrationSuccess": "Registration completed successfully!",
            "registrationCanceled": "Registration cancelled successfully!",
            "favoriteSuccess": "Item successfully added to favorites!",
            "favoriteCanceled": "Item successfully removed from favorites!",
            "reactionSuccess": "Reaction added successfully",
            "reactionCanceled": "Reaction removed successfully"
        }
    },
    "steps": {
        "basicData": "Basic Data",
        "offer": "Offer",
        "settings": "Settings",
        "contractManager": "Manage Contracts",
        "generalRules": "General Rules",
        "generalData": "General Data",
        "associateObjects": "Associate Objects"
    },
    "errors": {
        "notSupportStreaming": "Streaming support is not supported by the browser"
    },
    "repository": {
        "title": "Repository",
        "label": {
            "classic": "Classic Repository",
            "modern": "Modern Repository",
            "displayDuration": "Display Duration",
            "displayCode": "Display Code",
            "displayCreatedAt": "Display Created At",
            "displayOrigin": "Display Origin",
            "displayExpired": "Display Expired",
            "displayRequired": "Display Required",
            "subtitle": "Subtitle|Subtitles",
            "chapter": "Chapter|Chapters",
            "clickToVisit": "Click here to visit the link",
            "unavailable": "Content unavailable"
        },
        "help": {
            "supportedFilesTitle": "Supported file formats!",
            "supportedFilesMessage": "You can insert various types of learning objects such as: Video, PDF, Audio, Image, Document, and Scorm.",
            "supportedLinksTitle": "Supported link formats!",
            "supportedLinksMessage": "You can insert links from platforms like Vimeo, Youtube, among other links, including access to meeting rooms on Teams, Zoom, and Google Meet.",
            "unavailable": "For more information, contact yout manager or the platform support.",
            "cantUploadSubtitlesYet": "It is only possible to add subtitles after the video is uploaded and pre-processed.",
            "cantUploadChaptersYet": "It is only possible to add chapters after the video is uploaded and pre-processed.",
            "chaptersIntroduction": "Chapters are displayed on the video timeline and allow viewers to navigate more easily.",
            "noChaptersAddedYet": "No chapters have been added yet."
        },
        "feedback": {
            "chapterSLE": "The start of the chapter must be less than the end.",
            "chapterEGV": "The end of the chapter cannot be greater than the video duration.",
            "chapterOverlap": "A chapter cannot overlap another chapter."
        },
        "button": {
            "generateQrCode": "Generate QRCode"
        },
        "form": {
            "objectType": "Object Type",
            "objectDuration": "Object Duration",
            "durationAutoDetect": "Auto Detect",
            "create": "Create a new object",
            "edit": "Edit a object",
            "downloadable": "Downloadable",
            "selectScormType": "Select the type of Scorm",
            "selectCategory": "Select the category",
            "watermark": {
                "title": "Watermark",
                "enable": "Enable watermark",
                "userName": "User name",
                "userEmail": "User e-mail",
                "company": "Company",
                "unit": "Unit",
                "department": "Department",
                "position": "Position",
                "dateTime": "Date and time",
                "userIp": "User IP"
            },
            "chapter": {
                "start": "Chapter Start",
                "end": "Chapter End",
                "title": "Chapter Title"
            }
        }
    },
    "permissionGroup": {
        "form": {
            "titleAdd": "Add Permission Group",
            "titleEdit": "Edit - {name}",
            "name": "Permission Group Name",
            "namePlaceholder": "Enter the permission group name",
            "hint1": "Select all the functionalities that will be available to users in this group.",
            "selectFunctionality": "Select functionalities"
        }
    },
    "versionManager": {
        "newVersion": "New version!",
        "newAvailable": "A new version is available.",
        "newInstructions": "Click the 'Update' button or reload the page to apply the updates.",
        "update": "update"
    },
    role: {
        all: "All",
        admin: "Admin",
        manager: "Manager",
        student: "Student"
    },
    dashboard: {
        splash: {
            title: "Explore your Dashboards!",
            description: "Access the available panels in the top bar and view essential data to monitor performance and make strategic decisions.",
        }
    },
    assessmentGiveupResult: {
        title: 'Give up registered!',
        message: 'You can start a new assessment if there are still attempts available.'
    },
    contract: {
        label: {
            enableAAD: 'Allow user to log in via Single Sign On?',
            yes: 'Yes, Allow',
            no: 'No Allow',
            AAD: {
                id: "AAD Client ID",
                tenantId: "Tenant ID AAD",
                url: "AAD Instance URL",
                redirectUri: "AAD Authentication Return URL",
                provider: "Provider",
                clientSecret: "AAD Client Secret"
            }
        },
        tooltip: {
            enableAAD: "SSO (Single Sign-On) allows access to multiple systems with a single authentication. AAD (Azure Active Directory) is Microsoft's platform that manages this authentication, offering more security and convenience.",
            releaseAAD: "The settings entered will only take effect after the application is restarted. This means that after filling in and confirming the information, it will be necessary to restart the system for the changes to be properly applied. Please contact support or wait 48 hours for it to be applied automatically.",
            AAD: {
                id: "The Client ID is a unique identifier that is used by the application to identify itself to the Azure AD service.",
                tenantId: "The Tenant ID is a unique identifier that is used by the application to identify itself to the Azure AD service.",
                url: "Aadlnstance is the base URL of the Azure AD authentication service.",
                redirectUri: "It is used for authentication and authorization.",
                provider: "In this field you define the Company name that will be requested when the customer chooses to log in via SSO.",
                clientSecret: "It is the secret string that the application will use to prove its identity when requesting a Token. It can also be referred to as the Application Password."
            }
        },
        placeholder: {
            AAD: {
                id: "Enter the AAD ClientId",
                tenantId: "000000000000000000000000",
                url: "Enter the AAD Instance URL",
                redirectUri: "Enter the AAD Redirect URL",
                provider: "Enter the Provider",
                clientSecret: "Enter the AAD Client Secret"
            }
        }
    },
    subscription: {
        title: "My Subscription",
        expired: "Your subscription has expired or been canceled. You can only access the content already consumed to issue certificates. Renew your subscription to resume full access!",
        alert: {
            cancelled: "As requested, your subscription was canceled on {date}.",
            useUntil: "You can use the platform until {date}.",
            errorLoading: "Failed to load subscription information. Please try again later."
        },
        btn: {
            subscriptionPlan: "Subscription Plan",
            paymentHistory: "Payment History",
            cancel: "Cancel Subscription",
            reactivate: "Reactivate Subscription",
        },
        label: {
            inCash: "In cash",
            monthly: "Monthly"
        },
        info: {
            subscriberSince: "Subscriber since {date}",
            nextBilling: "Next billing on {date}",
            paidCreditCardDigits: "Credit card ending in {digits}",
            noSubscription: "You do not have an active plan."
        },
        cancelModal: {
            title: "Cancel Subscription",
            info: "By clicking on \"Cancel\", you will terminate your plan and stop the automatic billing, but you can continue to access the platform until the end of your period.",
            cantRefund: "It will not be possible to request a refund, as the deadline for this request has expired on {date}.",
            contactUs: "If you need help, please contact us.",
            reason: "Reason for cancellation",
            reasonPlaceholder: "Enter the reason for the cancellation (optional)",
            keep: "Keep subscription",
            cancel: "Cancel subscription"
        }
    },
    classroomDeviceOrientation: {
        message: 'For a better classroom experience, use your device horizontally.',
        dontShowAgain: 'Don\'t show again',
        close: 'Close Notification'
    },
    content: {
        label: {
            playbackSettings: "Playback Settings",
            currentImage: "Current Image",
            coverImage: "Video Cover Image",
            insertName: "Enter a name",
            insertLegend: "Upload a caption (click or drag here)",
            ready: "File ready",
            addLegend: "Add Caption",
            save: "Save changes to captions",
            objectInfo: "Object information",
            language: "Language",
        },
        info: {
            cardImageInfo: "This image will be displayed on the media card.",
            dropImage: "Drop the image here!",
            vttSrt: "Add captions or notes to videos. Supported formats are .VTT and .SRT.",
            changeType: "Do you really want to change the media type?",
            mediaInfo: "Links and Files are treated differently in the system."
        },
        placeholder: {
            clickHere: "Click here or drag an image",
            insertName: "Enter a name",
            insertSummary: "Enter a summary",
            insertSkills: "Link skills",
            insertSections: "Link sections",
            objectCode: "Unique object code (optional)",
            vttSrt: ".VTT or .SRT file, maximum 5 MB",
            dropLegend: "Drop the caption here!",
            noLegend: "No captions added yet...",
            selectLanguage: "Select a language"
        },
        feedback: {
            legendAdded: "Caption added",
        }
    }
};
